
import Vue from "vue";

import http from "@/http";

import { Store, Item, OwnerResponse, Owner } from "@/interfaces";

import MyCard from "@/components/owner/MyCard.vue";
import MyPager from "@/components/MyPager.vue";

import { mdiMagnify, mdiCloudDownload } from "@mdi/js";

import FileDownload from "js-file-download";

export interface Query {
  keyword: string;
  page: number;
  registered: boolean;
}

export interface DataType {
  owners: Owner[];
  query: Query;
  page: number;
  hasPrev: boolean;
  hasNext: boolean;
}

export default Vue.extend({
  components: {
    MyCard,
    MyPager
  },
  data(): DataType {
    return {
      owners: [],
      query: {
        keyword: "",
        page: 0,
        registered: false
      },
      page: 0,
      hasPrev: false,
      hasNext: false
    };
  },
  computed: {
    icon() {
      return { mdiMagnify, mdiCloudDownload };
    },
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "オーナー",
          disabled: true,
          to: "/owners"
        }
      ];
    }
  },
  watch: {
    async $route() {
      await this.fetchData();
    }
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async submit() {
      await this.$router
        .replace({
          path: "/owners",
          query: {
            keyword: this.query.keyword,
            page: this.query.page.toString(),
            registered: this.query.registered.toString()
          }
        })
        .catch(() => {});
    },
    async click() {
      const { data } = await http.get("download/owners", {
        params: {
          keyword: this.query.keyword,
          page: "1",
          registered: this.query.registered.toString()
        },
        responseType: "blob"
      });

      FileDownload(data, "owners.xlsx");
    },
    async next() {
      const page = +this.query.page;

      this.query.page = page + 1;

      await this.submit();
    },

    async prev() {
      const page = +this.query.page;

      this.query.page = page - 1;

      await this.submit();
    },
    handleQuery() {
      let keyword = "";
      let page = 1;
      let registered = false;

      if (typeof this.$route.query.keyword === "string") {
        keyword = this.$route.query.keyword;
      }

      if (typeof this.$route.query.page === "string") {
        page = +this.$route.query.page;
      }

      if (typeof this.$route.query.registered === "string") {
        registered = this.$route.query.registered === "true";
      }

      this.query = {
        keyword,
        page,
        registered
      };
    },
    async fetchData() {
      this.handleQuery();

      const params = {
        ...this.query
      };

      const { data } = await http.get<OwnerResponse>("owners", {
        params
      });

      this.hasNext = !!data.next_page_url;
      this.hasPrev = !!data.prev_page_url;

      this.owners = data.data;
    }
  }
});
