
import Vue, { PropType } from "vue";

import { OwnerResponse, Owner } from "@/interfaces";

import MyOverview from "@/components/owner/MyOverview.vue";

export default Vue.extend({
  components: {
    MyOverview
  },
  props: {
    owner: {
      type: Object as PropType<Owner>,
      required: true
    }
  },
  computed: {
    hasEmail(): boolean {
      return !!this.owner.user;
    }
  }
});
